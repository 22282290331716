
.CarouselContainer{
    display: flex;
    position: relative;
    overflow: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
    width: 1280px;
    /* border: 1px solid gray; */
    height: 500px;
    background-color: white;

}

.flip{
    position: absolute;
    left: 0px;
    width: 1280px;
    height: inherit;
    text-align: center;
    vertical-align: middle;
    background-color:  gray;
    transition: all 1s;
    transition-timing-function: ease;
    opacity: 100;
}

.prevOrnext{
    opacity:0;
}

 .slideButton{
    display:flex;
    align-items: center;
    justify-content: center;
    width: 1280px;
    /* margin: auto; */
    padding:0.5em;
    
    /* transform: translate(0,-25px);  */
    /* border: 1px solid black; */
    background-color: white;
    }
    
.slideButton>div{
    width: 50px;
    height: 7px;
    
    background: var(--shadow3);  

    cursor: pointer;

}
    
.slide_button_clicked{
    background: var(--third) !important;
}