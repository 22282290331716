
.InformationContainer{
  position: relative;
  display: flex;
  flex-flow: column;
  width: 1280px;
  height: 800px;
  background-color: var(--shadow4);
  /* 
  background: url('../assets/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  */
  padding-top: 1em;
  padding-bottom: 1em;
  /* border: 1px solid gray; */

}

.ControlContainer{
  display: flex;
  flex-flow: row;
  margin: auto;

  width: 100%;
  /* border: 1px solid gray; */
 

}

.InformationContainer > h3{
  color: var(--secondary);
  width: 1040px;
  margin: 0;
  margin: auto;
}

.InformationBox{
  position:relative;
  overflow: hidden;
  width: 1040px;
  height: 800px;
  margin: auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  /* border: 1px solid gray; */

}

.Card{
  position:absolute;
  transition: left 1s !important;
  transition-timing-function: ease !important;
  min-width: 500px;
  max-width: 500px;
  min-height: 720px;
  max-height: 720px;
  margin: 10px;
  cursor: pointer;
  /* border: 1px solid red; */

}

.Card:hover {
  box-shadow:0px 6px 8px 0px  var(--shadow1);
}

.Card:hover > .Content > h3{
 color: var(--primary);
}

.Card > .Content > h3{
  margin-top: 0;
}

.Card > .Content > p{
  font-size: 11pt;
  line-height: 1.6;
}

.Card > .CardThumbnail {
  width: 500px;
  height: 450px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100% ;
}

.Datetime{
  font-size: 8pt;
  color: gray;  
}

/* information */
.ModalInformationStyle{
  overflow: scroll
}

.ModalInformationContainer{
  display: block;
  position: absolute;
  text-align: left;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
  background-color: white;
  box-shadow: 24;
  
}

.ArticlePhotoCarouselContainer{
  display: flex;
  position: relative;
  overflow: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 600px;
  /* border: 1px solid gray; */
  height: 600px;
  background-color: white;
  margin: auto;
}

.ArticlePhoto{
  position: absolute;
  left: 0px;
  width: 600px;
  height: inherit;
  text-align: center;
  vertical-align: middle;
  transition: left 1s;
  transition-timing-function: ease;
}

.ModalInformationContainer > .ModalContent{
  padding: 1.5em;
}

.ModalInformationContainer > .TitleBar{
  width: 100%;
  text-align: right;
}

.ModalContent > .ContentParagraph{

  white-space: pre-line;
  line-height: 1.6;

}
